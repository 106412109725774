import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { SendOtpPasswordResponse } from '@models/auth.model';
import { StorageKey } from '@models/storage-key.model';
import { UserInfoService } from '@services/user-info/user-info.service';
import { firstValueFrom } from 'rxjs';
import { APP_ROUTES } from 'src/app/routes';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly userInfoService: UserInfoService
  ) {}

  get accessToken() {
    return localStorage.getItem(StorageKey.ATO) || '';
  }

  set accessToken(token: string) {
    localStorage.setItem(StorageKey.ATO, token);
  }

  public async loginByPhone(phone: string): Promise<void> {
    try {
      const req = this.http.post<void>(`${environment.publicUrl}/sendLoginPassword`, {
        phone,
      });
      const response = await firstValueFrom(req);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async sendOtpPassword(phone: string, otp: string): Promise<SendOtpPasswordResponse> {
    try {
      const req = this.http.post<SendOtpPasswordResponse>(`${environment.publicUrl}/sendOtpPassword`, {
        phone,
        otp,
        _platForm: '',
      });
      const resp = await firstValueFrom(req);
      this.accessToken = resp.access_token;
      this.router.navigateByUrl(APP_ROUTES.HOME);
      this.userInfoService.fetchCurrentUserInfo();
      return resp;
    } catch (error) {
      throw error;
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl(APP_ROUTES.ONBOARDING);
  }
}
